import React, {useMemo} from "react";
import { Button, makeStyles, withWidth} from "@material-ui/core";
import { Link } from "react-router-dom";
import {useHistory} from "react-router-dom";
//Styles
import styles from "assets/jss/material-dashboard-react/components/CustomPricingStyle";
import classNames from "classnames";

const useStyles = makeStyles(styles);

const PlanButton = ({
  plan,
  size = 'large',
  type = 'filled',
  state,
  defaultServiceNumber,
  defaultService,
  isSubmit = true,
}) => {

  const classes = useStyles();
  let history = useHistory();

  const {
    signUpPlanUrl,
    calcumatePlans,
    upDownGradePlanUrl,
    serviceNumber,
    service,
    originPricingPlan,
    cmPlanPayRec,
    currency,
    cancelPlanUrl,
    currentPlan,
  } = state;
  const subscriptionId = state?.signedInUser?.data?.user?.stripeAccount?.subscriptionId;

  const {thePlan, thePlanLabel} = plan;
  const url = useMemo(() => {
    if (thePlan?.toLowerCase() === "contact-us") {
      return '/contact-us';
    }
    if (state?.signedInUser?.token) {
      return upDownGradePlanUrl +
        "?plan=" +
        thePlan?.toLowerCase() +
        "&" +
        service +
        "=" +
            serviceNumber;
    }
    return state.signUpPlanUrl + "?plan=" + thePlan.toLowerCase() + '&' + service + '=' + serviceNumber;        
  }, [
    upDownGradePlanUrl,
    thePlan,
    service,
    serviceNumber,
    defaultServiceNumber,
    defaultService,
  ]);


  const originalPlan = originPricingPlan;  

  const sid = useMemo(() => {
    return plan?.[`sid${currency}`] || plan.sid;
  }, [
    plan,
    currency
  ]);

  const onSubmit = (event, url) => {
    console.log('url', url);
    event.preventDefault();
    if (isSubmit || !url.includes('complete')) {//submit instead of scroll
      history.push(url);
    } else {
      document.querySelector('.facility-select').scrollIntoView({
        behavior: "smooth",
        alignToTop: true
      });
    }
  };

  if (
    thePlan === currentPlan &&
    originalPlan === sid?.[(cmPlanPayRec ? 0 : 1)] &&
    !!subscriptionId &&
    defaultServiceNumber === serviceNumber &&
    defaultService === service
  ) {
    return (
      <Button
        component={Link}
        to={cancelPlanUrl + "?plan=" + thePlan.toLowerCase()}
        variant="outlined"
        color="default"
        size="small"
        fullWidth
        onClick={(event) => onSubmit(event, url)}
        disabled={!subscriptionId}
        className={classNames(classes.submitButton, classes.cancelBtn, {
          [classes.largeButton]: size === 'large',
          [classes.smallButton]: size === 'small',
          [classes.middleButton]: size === 'middle',
          [classes.submitButtonFilled]: type === 'filled',
          [classes.submitButtonPrimary]: type === 'outlined',
        })}
      >
        Cancel subscription
      </Button>
    );
  } else {
    return (
      <Button
        component={Link}
        to={url}
        variant="contained"
        color="primary"
        size="small"
        onClick={(event) => onSubmit(event, url)}
        fullWidth
        className={classNames("submitPlanButton", classes.submitButton, {
          [classes.largeButton]: size === 'large',
          [classes.smallButton]: size === 'small',
          [classes.middleButton]: size === 'middle',
          [classes.submitButtonFilled]: type === 'filled',
          [classes.submitButtonOutlined]: type === 'outlined',
        })}
        data-cy={"button-get-plan"}
      >
        Get {thePlanLabel || thePlan}
      </Button>
    );
  }
}

export default withWidth()(PlanButton);